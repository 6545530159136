/* External Libraries */
import { useRouter } from 'next/router';
import { CRMActivityType } from 'shared/dist/types';

import TimelineItem from './timeline-item';

export default function ActivityHorizontalTimeline({
  data,
  loading
}: {
  data: any[];
  loading: boolean;
}) {
  const router = useRouter();
  const actualBbox: any = null;

  const colors: string[] = [
    `rgba(41, 151, 132, .8)`,
    `rgba(251, 146, 60, 0.8)`,
    `rgba(41, 151, 132, .5)`,
    `rgba(251, 146, 60, 0.8)`
    // `rgba(251, 146, 60, 0.8)`,
    // `rgba(0, 132, 187, 0.8)`,
    // `rgba(41, 151, 132, 1)`
  ];

  if (data && data?.length < 1 && !loading) {
    return (
      <div className="w-full relative flex-col justify-center items-center">
        <div className="h-full absolute w-[1px] bg-bobo-proplight dark:bg-dark-morefaded left-[15px] z-10" />
        <TimelineItem index={100} item={{ category: CRMActivityType.noRecords }} />
      </div>
    );
  }

  return (
    <div className="w-full relative flex-col justify-center items-center">
      <div className="h-full absolute w-[1px] bg-gray-100 dark:bg-dark-morefaded left-[23px] z-10" />
      {data &&
        data?.map((d, idx) => {
          const backgroundColor: string = colors[0];

          return <TimelineItem index={idx} key={idx} item={d} />;
        })}
    </div>
  );
}
