/* External Libraries */
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
/* Components & Widgets */
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from '@/src/hooks/use-breakpoint';

import SignedGoogleMapsImage from '../../map/signed-google-maps-image';
import DashboardMap from './dashboard-map';

export default function LastViewed() {
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const router: any = useRouter();
  const { t } = useTranslation('common');

  const { isLg } = useBreakpoint({ breakpoint: 'lg' });

  useEffect(() => {
    fetch(`/api/prop/get-last-viewed`)
      .then((res) => {
        return res?.json();
      })
      .then((json) => {
        setList(json);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="relative">
        <div className="w-full relative flex-col justify-center items-center overflow-y-auto xl:h-[220px] bg-gray-50 mt-3 p-1 py-6 overflow-hidden rounded border border-gray-100 dark:bg-dark-faded dark:border-dark-morefaded">
          <div className="mt-2">
            <button className="flex mx-1 items-center text-xs justify-between group bg-gray-50 w-full even:bg-gray-100 p-2 dark:bg-dark-morefaded">
              <div className="w-[20px] mr-2">
                <ArrowRightCircleIcon className="text-bobo-prop dark:text-dark-prop w-5 h-5 group-hover:text-bobo-prophover dark:group-hover:text-dark-prophover" />
              </div>

              <div className="text-left leading-[14px] text-bobo-prop dark:text-dark-prop group-hover:text-bobo-prophover dark:group-hover:text-dark-prophover">
                <div>{t('general.fetching-data')}</div>
              </div>

              <div className="text-gray-400 dark:text-gray-600 font-light text-[12px] leading-[11px] flex-1 text-right"></div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isLg && <DashboardMap propertyList={list} />}
      {list && list?.length < 1 ? (
        <div className="flex flex-wrap w-full pb-20 mt-6 border-t border-gray-100 pt-4 dark:border-gray-500">
          <div className="p-4 bg-bobo-proplight dark:bg-dark-faded border border-dashed border-bobo-prop dark:border-dark-prop rounded-md">
            {t('pages.dashboard.no-vieved-properties')}
          </div>
        </div>
      ) : (
        <div className="relative rounded-b-md p-6 py-3 md:py-6 border-t">
          <div className="my-3">{t('navigation.crm.heading-recently-viewed')}</div>
          <div className="w-full relative grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3 border-gray-200  dark:border-dark-morefaded">
            {list
              ?.filter((a) => a?.authority_nickname)
              ?.map((a, idx) => {
                const latLong = `${a?.lat}, ${a?.lng}`;
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      router.push(`/crm/fastighet/${a?.uuid}/overview`);
                    }}
                    className="border border-gray-200 dark:border-dark-morefaded  items-start rounded-md text-xs justify-start group bg-gray-50 w-full dark:bg-dark-lessfaded hover:opacity-80 transition-opacity duration-300">
                    <div className="bg-gray-600 overflow-hidden relative rounded-t-md">
                      <SignedGoogleMapsImage
                        height={160}
                        width={400}
                        className="object-contain opacity-60"
                        src={`https://maps.googleapis.com/maps/api/streetview?size=400x160&location=${encodeURIComponent(
                          latLong
                        )}&fov=300&pitch=5&key=AIzaSyBYCMGQ9cdHlRBN414KW_VE0WgTzR6Sqow&source=outdoor`}
                      />
                    </div>
                    <div className="flex items-start justify-start">
                      <div className="text-left text-sm leading-[14px] p-2 text-gray-900 dark:text-dark-prop group-hover:text-bobo-prophover dark:group-hover:text-dark-prophover">
                        <div className="text-[11px] font-bold mb-1">{a.county}</div>
                        <div>{a?.authority_nickname?.replace(a.county, '')}</div>
                        <div className="text-gray-500 italic font-normal text-[12px] leading-[11px] flex-1 text-left dark:text-gray-300 mt-2">
                          {t('general.visited', { since: moment(a?.timestamp * 1000).fromNow() })}
                        </div>
                      </div>
                    </div>
                  </button>
                );
              })}
          </div>
          {/*<div className="absolute bottom-0 bg-gradient-to-b from-transparent to-gray-50 dark:to-dark-primary h-0 xl:h-16 z-50 w-full"></div>*/}
        </div>
      )}
    </>
  );
}
