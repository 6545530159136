/* External Libraries */
import {
  ArchiveBoxArrowDownIcon,
  ArrowsRightLeftIcon,
  BuildingOffice2Icon,
  ChatBubbleLeftIcon,
  LockClosedIcon,
  PlusIcon,
  RectangleStackIcon,
  WindowIcon
} from '@heroicons/react/24/solid';
import cls from 'classnames';
import DOMPurify from 'dompurify';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';
import { CRMActivityType, GotoFeature } from 'shared/dist/types';
import { useSlideIn } from 'src/context/slide-in-context';

import { useDialog } from '@/src/context/dialog-context';
import { useBreakpoint } from '@/src/hooks/use-breakpoint';

const TimelineItem = ({ item, index }: { item: any; index: number }) => {
  const [open, setOpen] = useState<boolean>(false);

  const router = useRouter();
  const { t } = useTranslation('common');

  const { dialog, setDialog } = useDialog();
  const { slideIn, setSlideIn } = useSlideIn();

  const { isMd } = useBreakpoint({ breakpoint: 'md' });
  const { isLgOnly } = useBreakpoint({ breakpoint: 'lg' });
  const isMobile = !isMd || isLgOnly;

  let icon: JSX.Element | null = null;
  let actionText: JSX.Element | null = null;
  let del = false;

  const isPrivate: boolean = item?.private;
  const creator: string = isPrivate ? t('general.you') : item?.user_name;
  const prop: string = item?.property;
  const propCount: number = item?.properties_added;
  const list: string = item?.list;
  const board: string = item?.board;

  let sinceWhen: string | null = moment(item?.timestamp * 1000)?.fromNow();

  let categoryHeader = t('general.unknown');
  let baseHeight = 55;

  const categoryHeaders = {
    noRecords: t('crm.activity-type.no-records'),
    addBoard: t('crm.activity-type.board-added'),
    addPrivateBoard: t('crm.activity-type.private-board-added'),
    updateBoard: t('crm.activity-type.board-updated'),
    updatePrivateBoard: t('crm.activity-type.private-board-updated'),
    delBoard: t('crm.activity-type.board-removed'),
    delPrivateBoard: t('crm.activity-type.private-board-removed'),
    addComment: t('crm.activity-type.comment-added'),
    addPrivateComment: t('crm.activity-type.private-comment-added'),
    addList: t('crm.activity-type.list-added'),
    addPrivateList: t('crm.activity-type.private-list-added'),
    updateList: t('crm.activity-type.list-updated'),
    updatePrivateList: t('crm.activity-type.private-list-updated'),
    delList: t('crm.activity-type.list-removed'),
    delPrivateList: t('crm.activity-type.private-list-removed'),
    addProperty: t('crm.activity-type.property-added'),
    addPropertyPrivate: t('crm.activity-type.property-added-private'),
    rmProperty: t('crm.activity-type.property-removed'),
    rmPropertyPrivate: t('crm.activity-type.property-removed-private'),
    movedCard: t('crm.activity-type.card-moved'),
    movedCardPrivate: t('crm.activity-type.card-moved-private'),
    movedCardFromArchive: t('crm.activity-type.card-moved-from-archive'),
    movedCardFromArchivePrivate: t('crm.activity-type.card-moved-from-archive-private'),
    archiveProperty: t('crm.activity-type.archive-property'),
    archivePrivateProperty: t('crm.activity-type.archive-property-private'),
    publishedPrivateBoard: t('crm.activity-type.board-added'),
    archiveList: t('crm.activity-type.list-archived'),
    archivePrivateList: t('crm.activity-type.private-list-archived')
  };

  const actionTextItems = {
    addBoard: (
      <Trans i18nKey="crm.activity-type.board-added-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har lagt till tavlan
        <span className="italic font-demi">{{ board }}</span>.
      </Trans>
    ),
    publishedPrivateBoard: (
      <Trans i18nKey="crm.activity-type.private-board-published-desc">
        <span className="font-demi">{{ creator }}</span> har delat tavlan
        <span className="italic font-demi">{{ board }}</span>.
      </Trans>
    ),
    updateBoard: (
      <Trans i18nKey="crm.activity-type.board-updated-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har uppdaterat namnet på en tavla som nu
        heter
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    delBoard: (
      <Trans i18nKey="crm.activity-type.board-removed-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har tagit bort tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    addComment: (
      <Trans i18nKey="crm.activity-type.comment-added-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har lagt till en kommentar för
        <span className="uppercase font-demi"> {{ prop }}</span> i
        <span className="italic font-demi"> {{ list }}</span> i tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    addList: (
      <Trans i18nKey="crm.activity-type.list-added-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har lagt till listan
        <span className="font-demi italic"> {{ list }}</span> i tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    archiveList: (
      <Trans i18nKey="crm.activity-type.list-archived-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har flyttat listan
        <span className="font-demi italic"> {{ list }}</span> till
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    updateList: (
      <Trans i18nKey="crm.activity-type.list-updated-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har uppdaterat
        <span className="italic font-demi"> {{ list }}</span> i tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    delList: (
      <Trans i18nKey="crm.activity-type.list-removed-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har raderat listan
        <span className="italic font-demi"> {{ list }}</span> i tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    addProperty: (
      <Trans i18nKey="crm.activity-type.property-added-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har lagt till
        <span className="uppercase font-demi"> {{ prop }}</span> till
        <span className="italic font-demi"> {{ list }}</span> i tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    rmProperty: (
      <Trans i18nKey="crm.activity-type.property-removed-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har tagit bort
        <span className="uppercase font-demi"> {{ prop }}</span> från tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    movedCard: (
      <Trans i18nKey="crm.activity-type.card-moved-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har flyttat
        <span className="uppercase font-demi"> {{ prop }}</span> till
        <span className="italic font-demi"> {{ list }}</span> i tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    addProperties: (
      <Trans
        i18nKey="crm.activity-type.add-properties-desc"
        count={propCount}
        context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har lagt till
        <span className="uppercase font-demi">{{ propCount }}</span>
        fastighet i <span className="italic font-demi"> {{ list }}</span> i tavlan
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    archiveProperty: (
      <Trans i18nKey="crm.activity-type.archive-property-desc" context={isPrivate ? 'private' : ''}>
        <span className="font-demi">{{ creator }}</span> har flyttat
        <span className="uppercase font-demi"> {{ prop }}</span> till
        <span className="italic font-demi"> {{ board }}</span>.
      </Trans>
    ),
    noRecords: <>{t('crm.activity-type.no-records-description')}</>
  };

  switch (item?.category) {
    case CRMActivityType.noRecords:
      icon = <PlusIcon className="w-5 h-5" />;
      categoryHeader = categoryHeaders.noRecords;
      actionText = actionTextItems.noRecords;
      sinceWhen = null;
      baseHeight = 120;
      break;
    case CRMActivityType.addProperty:
      icon = <BuildingOffice2Icon className="w-4 h-4" />;
      categoryHeader = isPrivate ? categoryHeaders.addPropertyPrivate : categoryHeaders.addProperty;
      actionText = actionTextItems.addProperty;
      break;
    case CRMActivityType.addProperties:
      icon = <BuildingOffice2Icon className="w-4 h-4" />;
      categoryHeader = categoryHeaders.addProperty;
      actionText = actionTextItems.addProperties;
      break;
    case CRMActivityType.rmProperty:
      del = true;
      categoryHeader = isPrivate ? categoryHeaders.rmPropertyPrivate : categoryHeaders.rmProperty;
      icon = <BuildingOffice2Icon className="w-4 h-4" />;
      actionText = actionTextItems.rmProperty;
      break;
    case CRMActivityType.addBoard:
      categoryHeader = categoryHeaders.addBoard;
      icon = <WindowIcon className="w-4 h-4" />;
      actionText = actionTextItems.addBoard;
      break;
    case CRMActivityType.addPrivateBoard:
      categoryHeader = categoryHeaders.addPrivateBoard;
      icon = <WindowIcon className="w-4 h-4" />;
      actionText = actionTextItems.addBoard;
      break;
    case CRMActivityType.publishedPrivateBoard:
      categoryHeader = categoryHeaders.publishedPrivateBoard;
      icon = <WindowIcon className="w-4 h-4" />;
      actionText = actionTextItems.publishedPrivateBoard;
      break;
    case CRMActivityType.updateBoard:
      icon = <WindowIcon className="w-4 h-4" />;
      categoryHeader = categoryHeaders.updateBoard;
      actionText = actionTextItems.updateBoard;
      break;
    case CRMActivityType.updatePrivateBoard:
      icon = <WindowIcon className="w-4 h-4" />;
      categoryHeader = categoryHeaders.updatePrivateBoard;
      actionText = actionTextItems.updateBoard;
      break;
    case CRMActivityType.delBoard:
      del = true;
      icon = <WindowIcon className="w-4 h-4" />;
      categoryHeader = isPrivate ? categoryHeaders.delPrivateBoard : categoryHeaders.delBoard;
      actionText = actionTextItems.delBoard;
      break;
    case CRMActivityType.addList:
      icon = <RectangleStackIcon className="w-4 h-4" />;
      categoryHeader = isPrivate ? categoryHeaders.addPrivateList : categoryHeaders.addList;
      actionText = actionTextItems.addList;
      break;
    case CRMActivityType.updateList:
      categoryHeader = isPrivate ? categoryHeaders.updatePrivateList : categoryHeaders.updateList;
      icon = <RectangleStackIcon className="w-4 h-4" />;
      actionText = actionTextItems.updateList;
      break;
    case CRMActivityType.archiveList:
      categoryHeader = isPrivate ? categoryHeaders.archivePrivateList : categoryHeaders.archiveList;
      icon = <ArchiveBoxArrowDownIcon className="w-4 h-4" />;
      actionText = actionTextItems.archiveList;
      break;
    case CRMActivityType.delList:
      del = true;
      categoryHeader = isPrivate ? categoryHeaders.delPrivateList : categoryHeaders.delList;
      icon = <RectangleStackIcon className="w-4 h-4" />;
      actionText = actionTextItems.delList;
      break;
    case CRMActivityType.addComment:
      categoryHeader = isPrivate ? categoryHeaders.addPrivateComment : categoryHeaders.addComment;
      icon = <ChatBubbleLeftIcon className="w-4 h-4" />;
      actionText = actionTextItems.addComment;
      break;
    case CRMActivityType.movedCard:
      categoryHeader = isPrivate ? categoryHeaders.movedCardPrivate : categoryHeaders.movedCard;
      icon = <ArrowsRightLeftIcon className="w-4 h-4" />;
      actionText = actionTextItems.movedCard;
      break;
    case CRMActivityType.movedCardFromArchive:
      categoryHeader = isPrivate
        ? categoryHeaders.movedCardFromArchivePrivate
        : categoryHeaders.movedCardFromArchive;
      icon = <ArrowsRightLeftIcon className="w-4 h-4" />;
      actionText = actionTextItems.movedCard;
      break;
    case CRMActivityType.archiveProperty:
      categoryHeader = isPrivate
        ? categoryHeaders.archivePrivateProperty
        : categoryHeaders.archiveProperty;
      icon = <ArchiveBoxArrowDownIcon className="w-4 h-4" />;
      actionText = actionTextItems.archiveProperty;
      break;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(item?.body?.text)
  });

  return (
    <motion.div
      initial={{ height: baseHeight }}
      animate={{
        height: open ? (isMobile ? baseHeight + 95 : baseHeight + 50) : baseHeight,
        transition: { duration: 0.3 }
      }}
      exit={{ height: baseHeight, transition: { duration: 0.3 } }}
      className="relative group flex overflow-hidden mt-4 pb-4 items-start justify-start w-full pl-1 odd:bg-gray-100 dark:odd:bg-dark-lessfaded rounded-md">
      <div
        className={cls(
          `rounded-full w-8 h-8 flex items-center justify-center mt-3 relative z-20 ml-1 transition-colors duration-300`,
          {
            'bg-bobo-councillight dark:bg-dark-lessfaded text-bobo-council group-hover:bg-bobo-council group-hover:text-white':
              del
          },
          {
            'bg-gray-100 group-hover:bg-bobo-prop group-hover:text-white text-gray-800 dark:bg-dark-lessfaded dark:group-hover:bg-dark-prophover dark:text-gray-300':
              !del
          }
        )}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick(e);
          }
        }}
        role={`button`}
        tabIndex={0}>
        {icon}
      </div>
      {isPrivate && (
        <div
          className={cls(
            `rounded-full p-0.5 -ml-3 mt-7 relative z-20 transition-colors duration-300`,
            {
              'bg-bobo-proplight group-hover:bg-bobo-prop group-hover:text-white text-bobo-prop dark:bg-dark-prop dark:group-hover:bg-dark-prophover dark:text-white':
                !del
            },
            {
              'bg-bobo-council dark:bg-dark-morefaded dark:text-white group-hover:bg-bobo-councillight group-hover:text-bobo-council text-white':
                del
            }
          )}>
          <LockClosedIcon className="w-2 h-2" />
        </div>
      )}
      <div
        className={cls(`justify-start items-start ml-4 overflow-hidden text-left relative w-4/5`)}>
        <div className="font-regular text-gray-600 dark:text-gray-200 text-xs w-full flex justify-between items-start pb-2">
          <div
            className="items-start pt-1 flex-col justify-center"
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClick(e);
              }
            }}
            role={`button`}
            tabIndex={0}>
            <div
              className={cls(
                `text-[10px] uppercase font-demi text-left`,
                {
                  'text-bobo-council': del
                },
                {
                  'text-bobo-prop dark:text-dark-prop': !del
                }
              )}>
              {categoryHeader}
            </div>
            <div>{actionText ?? t('general.no-data')}</div>
            {sinceWhen ? (
              <div className="italic text-xs text-gray-500 font-light text-left dark:text-gray-400">
                {t('crm.data.updated', { sinceWhen })}
              </div>
            ) : null}
            {item?.body && item?.body?.text ? (
              <div
                className="mt-4 bg-gray-50 dark:bg-dark-morefaded dark:text-white rounded-md shadow p-3 w-5/6"
                dangerouslySetInnerHTML={sanitizedData()}></div>
            ) : null}
          </div>
        </div>
        <div className="flex justify-start text-xs text-bobo-prop dark:text-dark-prop font-medium relative z-20 space-x-8 mt-2">
          {![CRMActivityType.noRecords, CRMActivityType.delBoard]?.includes(item?.category) ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                router.push(`/crm/${item?.boardId}`);
              }}
              className="flex items-center font-regular px-4 py-1 rounded-md bg-bobo-proplight dark:bg-dark-prop dark:hover:bg-dark-prophover text-bobo-prop hover:text-bobo-prophover dark:text-white transition-colors duration-300">
              <RectangleStackIcon className="w-4 h-4 md:mr-2" />{' '}
              <span className="hidden md:block">{board}</span>
            </button>
          ) : null}
          {item?.category === CRMActivityType.noRecords ? (
            <div className="inset-0 flex items-center justify-center">
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setDialog({
                    dialogType: 'prompt-crm-dialog-switcher',
                    object: {
                      initStep: 10
                    }
                  });
                }}
                className={`text-xs text-white bg-bobo-prop dark:bg-dark-prop hover:bg-bobo-prophover dark:hover:bg-dark-prophover p-4 py-2 rounded-md shadow transition-colors duration-300 whitespace-nowrap`}>
                {t('crm.user-actions.add-board')}
              </button>
            </div>
          ) : null}
          {![
            CRMActivityType.addBoard,
            CRMActivityType.addPrivateBoard,
            CRMActivityType.updateBoard,
            CRMActivityType.delBoard,
            CRMActivityType.addList,
            CRMActivityType.updateList,
            CRMActivityType.delList,
            CRMActivityType.addProperties,
            CRMActivityType.publishedPrivateBoard,
            CRMActivityType.updatePrivateBoard,
            CRMActivityType.archiveList
          ]?.includes(item?.category) && item?.category !== CRMActivityType.noRecords ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSlideIn({
                  type: GotoFeature.PROPERTY,
                  object: {
                    uuid: item?.propertyId,
                    authority_nickname: item?.property,
                    boardId: item?.boardId
                  }
                });
              }}
              className="flex items-center font-light px-4 py-1 rounded-md bg-bobo-prop dark:bg-dark-prop text-white hover:bg-bobo-prophover dark:hover:bg-dark-prophover transition-colors duration-300">
              <BuildingOffice2Icon className="w-4 h-4 md:mr-2" />{' '}
              <span className="hidden md:block">{item?.property}</span>
            </button>
          ) : null}
        </div>
        {!open && item?.category !== CRMActivityType.noRecords ? (
          <div
            onClick={!open && handleClick}
            onKeyPress={!open && handleClick}
            role={`button`}
            tabIndex={0}
            className={cls(`absolute bottom-0 h-4 w-full`, {
              'bg-gradient-to-b z-50 from-transparent to-white dark:to-dark-primary': !open
            })}
          />
        ) : null}
      </div>
    </motion.div>
  );
};

export default TimelineItem;
