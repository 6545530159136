/* External Libraries */
import { ArrowsRightLeftIcon, BuildingOffice2Icon } from '@heroicons/react/24/solid';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import BoardCounter from './items/board-counter';
import CRMActivity from './items/crm-activity';
/* Components & Widgets */
import LastViewed from './items/last-viewed';
import PropSection from './items/prop-section';

export default function DashboardIndex() {
  const { t } = useTranslation('common');

  return (
    <div className="text-xs lg:text-sm w-full relative z-20 flex flex-col h-[calc(100dvh-70px)] md:h-[100dvh] mt-[10px] md:mt-0">
      <div className="flex border-b w-full justify-between items-center shadow border-gray-200 dark:border-dark-morefaded bg-white dark:bg-dark-primary p-6 lg:p-0 lg:px-5 2xl:px-20 ">
        <div className="font-demi">
          <div className="text-smaller">{t('crm.modals.get-started')}</div>
          <div className="grid grid-cols-1 gap-8 mt-3">
            <div className="w-full max-w-[500px] flex gap-8 items-center">
              <div className="rounded-full bg-bobo-proplight dark:bg-dark-lessfaded flex justify-center items-center text-bobo-prop dark:text-dark-prop font-bold w-[45px] h-[45px] min-w-[45px]">
                <BuildingOffice2Icon className="w-6 h-6" />
              </div>

              <div>
                <h3 className="text-xs lg:text-sm text-gray-500 dark:text-gray-200 font-demi">
                  {t('crm.info.controll-and-insight')}
                </h3>

                <p className="text-xs lg:text-smaller font-light text-gray-500 dark:text-gray-300">
                  {t('crm.info.controll-and-insight-description')}
                </p>
              </div>
            </div>

            <div className="w-full lg:max-w-[500px] flex gap-8 items-center">
              <div className="rounded-full bg-bobo-proplight dark:bg-dark-lessfaded flex justify-center items-center text-bobo-prop dark:text-dark-prop font-bold w-[45px] h-[45px] min-w-[45px]">
                <ArrowsRightLeftIcon className="w-6 h-6" />
              </div>

              <div>
                <h3 className="text-xs lg:text-sm text-gray-500 dark:text-gray-200 font-demi">
                  {t('crm.info.organise-and-manage')}
                </h3>

                <p className="text-xs lg:text-smaller font-light text-gray-500 dark:text-gray-300">
                  {t('crm.info.organise-and-manage-description')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/3 text-right justify-end max-h-[300px] hidden lg:flex">
          <Image
            src={`/images/illustrations/dataroom.svg`}
            alt={t('general.image-alt')}
            height={300}
            width={300}
          />
        </div>
      </div>

      <div className="flex flex-col xl:flex-row lg:px-5 2xl:px-14 justify-start bg-gray-50 dark:bg-dark-lessfaded flex-1 py-5">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full 2xl:mx-6 gap-3 items-start">
          <div className="xl:grid xl:grid-cols-2 gap-3 space-y-3 xl:space-y-0">
            <div className="w-full bg-white dark:bg-dark-primary lg:rounded-md border border-gray-200 dark:border-dark-morefaded p-6 py-3">
              <BoardCounter />
            </div>

            <div className="2xl:col-span-2">
              <div className="w-full bg-white dark:bg-dark-primary lg:rounded-md border border-gray-200 dark:border-dark-morefaded p-6 py-3">
                <CRMActivity />
              </div>
            </div>

            <div className="lg:col-span-3 hidden lg:block">
              <div className="w-full bg-white dark:bg-dark-primary lg:rounded-md border border-gray-200 dark:border-dark-morefaded p-6 py-3">
                <PropSection />
              </div>
            </div>
          </div>

          <div>
            <div className="w-full bg-white dark:bg-dark-primary lg:rounded-md border border-gray-200 dark:border-dark-morefaded overflow-hidden">
              <LastViewed />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
