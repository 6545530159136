import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { getActiveActor } from '@/src/lib/user-client-wrapper';

import ActivityHorizontalTimeline from './horitontal-activity-timeline';

export default function CRMLogSection() {
  const [latestCrmActivity, setLatestCrmActivity] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const active = getActiveActor();
  const { t } = useTranslation('common');

  useEffect(() => {
    fetch(`/api/crm/getLatestCrmActivity`, {
      method: 'POST',
      body: JSON.stringify({
        actor_uuid: active?.actor_uuid
      })
    })
      .then((res) => {
        return res?.json();
      })
      .then((res) => {
        setLatestCrmActivity(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="relative">
      <div className="xl:text-smaller text-xs font-demi text-gray-700 dark:text-white xl:leading-[20px] leading-[20px]">
        {t('crm.activity-timeline-heading')}
      </div>
      {loading ? <LinearProgress className="w-full" /> : <div className="w-full h-2" />}
      <div className="w-full relative flex-col justify-center items-center overflow-hidden overflow-y-auto pb-8">
        <ActivityHorizontalTimeline loading={loading} data={latestCrmActivity} />
      </div>

      {latestCrmActivity && latestCrmActivity?.length > 0 ? (
        <div className="absolute bottom-0 bg-gradient-to-b from-transparent to-gray-50 dark:to-dark-primary h-8 xl:h-16 z-50 w-full"></div>
      ) : null}
    </div>
  );
}
